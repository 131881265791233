import React from "react";
import classNames from "classnames/bind";
import { graphql } from "gatsby";
import truncate from "@v4/utils/truncate";
import * as builtInNewsStyles from "./builtin_news.module.css";

const cx = classNames.bind(builtInNewsStyles);

export const query = graphql`
    query RecentBlogsQuery {
        allContentfulBlog(filter: { node_locale: { eq: "en" } }, limit: 5, sort: { fields: publishDate, order: DESC }) {
            nodes {
                id
                title
                previewText
                slug
                languages
                node_locale
            }
        }
    }
`;

const BuiltInNews = ({ data }) => {
    const recentBlogPosts = data.allContentfulBlog.nodes;

    return (
        <main className={cx("builtInNews")}>
            <h2>
                It's easier than ever to port your Talend jobs to Cloud. Learn More from our{" "}
                <a href="/lp/dependable-data-in-the-cloud/?ty=webinar">Talend Experts</a>
            </h2>
            <h3>Upgrade to Talend Winter ’20 (Talend 7.3)</h3>
            <p>&bull;&nbsp;Capture data intelligence at first sight with Talend Data Inventory</p>
            <p>&bull;&nbsp;Standardize data at scale with intelligent data quality and explainable AI</p>
            <p>
                &bull;&nbsp;Benefit from the latest component updates for AWS, Azure, Cloudera, Databricks, Google,
                Snowflake and Workday
            </p>
            <p>
                &bull;&nbsp;<a href="/products/whats-new/">Learn more</a> or{" "}
                <a href="mailto:customersuccess@talend.com">Email</a>
            </p>
            <ul className={cx("blogList")}>
                {data &&
                    recentBlogPosts &&
                    recentBlogPosts.map((post, index) => (
                        <li key={index}>
                            <div>
                                <strong>{post.title}</strong>
                            </div>
                            <div>{truncate(post.previewText, 55)}</div>
                        </li>
                    ))}
            </ul>
        </main>
    );
};

export default BuiltInNews;
